$('.js-blog-youtube__slider').slick({
  slidesToShow: 2,
  dots: false,
  infinite: false,
  arrows: true,
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 1
    }
  },{
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      arrows: false,
      dots: true
    }
  }]
});

$('.js-article-news__slider').slick({
  slidesToShow: 3,
  dots: false,
  infinite: true,
  arrows: false,
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      dots: true
    }
  },{
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      dots: true
    }
  }]
});

if ($(window).width() < 768) {
  $(".right-popular__slider").addClass("js-blog-more__slider");
} else {
  $(".right-popular__slider").removeClass("js-blog-more__slider");
}

$('.js-blog-more__slider').slick({
  slidesToShow: 1,
  dots: true,
  infinite: true,
  arrows: false
});

function contactTabs() {
  $('.tabs-stage .tabs-stage__block').css('display', 'none');
  $('.tabs-stage .tabs-stage__block:first').css('display', 'flex');
  $('.tab-nav a:first').addClass('tab-active');

  $('.tab-nav a').on('click', function(event){
    event.preventDefault();
    $('.tab-nav a').removeClass('tab-active');
    $(this).addClass('tab-active');
    $('.tabs-stage .tabs-stage__block').css('display', 'none');
    $($(this).attr('href')).css('display', 'flex');
  });
}
contactTabs();

if ($(window).width() < 992) {
  function scrollToMap() {
    $('#mapNavigation > a').each(function() {
      $(this).on('click', function() {
        $('html, body').animate({
          scrollTop: $("#map").offset().top - 50
        }, 2000);
      });
    });
  }
  scrollToMap();
}
